//local connection
// const apiUrl = {
//     url: "http://localhost:8888/express/",
// }

//live connection
const apiUrl = {
    url: "https://pearsbe.pearsportal.com/express/",
}

export default apiUrl;