import React from 'react';
import { 
    Button, 
    Container, 
    Nav,
    Navbar,
    Offcanvas
} from 'react-bootstrap';
import logo_head from '../../images/logo.png';
import { FaHome } from "react-icons/fa";
import { MdOutlineInfo } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { IoMdPricetags } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";

const Home: React.FC = () =>{

// ****** Navigate to path when click *****
const handleItemClick = (path: string) => {
    // setSessionVariable('setSelectedItem', path);
    // navigate(path);
  };
  return (
    <Container fluid className='bg-dark' data-bs-theme="dark" style={{position:'fixed', zIndex: 1}}>
        {['sm'].map((expand, index) => (
            <Navbar  key={index} expand={expand}  bg="dark" data-bs-theme="dark" className="shadow-lg ps-5">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={logo_head} alt="" width="45" height="40"/>
                        &nbsp;Pears Portal
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        data-bs-theme="dark"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Pears Portal
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href="/"><FaHome size={15}/> Home</Nav.Link>
                                <Nav.Link href="/about"><MdOutlineInfo size={15}/> About Us</Nav.Link>
                                <Nav.Link href="/services"><GrServices size={15}/> Services</Nav.Link>
                                <Nav.Link href="/pricing"><IoMdPricetags size={15}/> Pricing</Nav.Link>
                                <Nav.Link href="#action2"><BiSolidPhoneCall size={15}/> Contact Us</Nav.Link>
                                {/* <NavDropdown
                                    title="Dropdown"
                                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action4">
                                        Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action5">
                                        Something else here
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                            </Nav>
                            {/* <Form className="d-flex">
                                <Form.Control
                                    type="search"
                                    placeholder="Search"
                                    className="me-2"
                                    aria-label="Search"
                                />
                                <Button variant="outline-success">Search</Button>
                            </Form> */}
                            <Nav className="ms-auto">
                                <Button onClick={() => handleItemClick('/login')} variant="light" type="submit" className="btn btn-block rounded-pill m-1" >
                                    Log In
                                </Button>
                                <Button onClick={() => handleItemClick('/activation')} variant="primary" type="submit" className="btn btn-block rounded-pill m-1" >
                                    Sign Up
                                </Button>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        ))}
    </Container>
  );
};

export default Home;