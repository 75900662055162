import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Card,
  Row,
  Col,
  Alert,
  Carousel,
  Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Sidebar  from './sidebar';
import booking from '../../images/booking.jpg';
import shopping from '../../images/shopping.jpg';
import billing from '../../images/billing.jpg';
import dtr from '../../images/dtr.jpg';
import payroll from '../../images/payroll.png';
import enrollment from '../../images/enrollment.jpg';
import linao from '../../images/linao.png';
import south from '../../images/south.png';
import ordering from '../../images/ordering.png';
import inventory from '../../images/inventory.png';

const Home: React.FC = () =>{
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container fluid data-bs-theme="dark" style={{background:'#000000'}}>
        <Row>
          <Col >      
            <Sidebar  />
          </Col>
          <Col xs={12} className={`mt-5 pt-4 ${isSmallScreen ? '' : 'ps-5'}`}> {/* Conditional rendering for ps-5 class */}
            <div style={{width:"400px"}}></div>
              <div className={`mt-3 ${isSmallScreen ? '' : 'ms-4 me-1'}`}> {/* Conditional rendering for ms-4 class */}

                <Row xs={1} md={4} className="g-1 m-1">
                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/booking_menu" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={booking} height="200px" />
                        <Card.Body>
                          <Card.Title>Booking Calendar</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Booking system for your business</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={shopping} height="200px" />
                        <Card.Body>
                          <Card.Title>Shopping Cart</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Online shopping for your store</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="https://billing.pearsportal.com/" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={billing} height="200px" />
                        <Card.Body>
                          <Card.Title>Billing System</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Generate your bills</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={enrollment} height="200px" />
                        <Card.Body>
                          <Card.Title>Online Enrollment</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">School record online</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>
                </Row>

                <Row xs={1} md={4} className="g-1 m-1">
                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={inventory} height="200px" />
                        <Card.Body>
                          <Card.Title>Inventory</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Inventory Managment System</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={ordering} height="200px" />
                        <Card.Body>
                          <Card.Title>Order System</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Restaurant ordering system</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>
                  
                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={dtr} height="200px" />
                        <Card.Body>
                          <Card.Title>DTR</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Daily time record</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="m-1 rounded shadow-lg">
                      <Link to="/#" style={{ textDecoration: 'none'}} className='text-light'>
                        <Card.Img variant="top" src={payroll} height="200px" />
                        <Card.Body>
                          <Card.Title>Payroll System</Card.Title>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Payslip and more</small>
                        </Card.Footer>
                      </Link>
                    </Card>
                  </Col>


                </Row>

                <Alert variant="white" className='text-light mt-5'>
                    <Alert.Heading>Projects</Alert.Heading>
                    <hr />
                </Alert>
                
                <Row>
                  <Col xs={6} md={2} className='text-center text-light'>
                    <Link to="#" style={{ textDecoration: 'none'}}>
                      <Image src={linao} rounded className='w-100'/>
                      
                    </Link>
                    <i className='text-muted'>By: Joseph Bryan Egoc</i>
                  </Col>
                  <Col xs={6} md={2} className='text-center text-light'>
                    <Link to="#" style={{ textDecoration: 'none'}}>
                      <Image src={south} rounded className='w-100'/>
                    </Link>
                    <i className='text-muted'>By: Joseph Bryan Egoc</i>
                  </Col>
                  <Col xs={6} md={2}>
                    {/* <Image src={linao} rounded className='w-100'/> */}
                  </Col>
                  <Col xs={6} md={2}>
                    {/* <Image src={south} roundedCircle className='w-100'/> */}
                  </Col>
                  <Col xs={6} md={2}>
                    {/* <Image src={linao} rounded className='w-100'/> */}
                  </Col>
                  <Col xs={6} md={2}>
                    {/* <Image src={south} roundedCircle className='w-100'/> */}
                  </Col>
                </Row>

                


              </div>
          </Col> 
      </Row>
    </Container>
  );
};

export default Home;