import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/common/home';
// import LoginForm from './pages/login/login';
import Header from './pages/common/header';
import About from './pages/common/about';
import Services from './pages/common/services';
import Footer from './pages/common/footer';
import Pricing from './pages/common/pricing';
import Billing_Menu from './pages/billing/billing_menu';
import Booking_Menu from './pages/booking/booking_menu';
import Upload_Text from './pages/others/upload_text';

//admin
//user

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/billing_menu" element={<Billing_Menu />} />
        <Route path="/booking_menu" element={<Booking_Menu />} />
        <Route path="/upload_text" element={<Upload_Text />} />
        {/* <Route path="/login" element={<LoginForm />} />
        <Route path="/header" element={<Header />} />
        <Route path="/activation" element={<Activation />} /> */}
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
